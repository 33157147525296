import styled from 'styled-components';

export const Button = styled.button`
  background-color: ${(p) => p.theme.colors.primary};
  color: white;
  padding: 16px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }
`;
