import { useRef, useEffect, useCallback } from 'react';
import { EventEmitter } from 'events';
import chevronRight from './icons/chevron-right.svg';
import styled from 'styled-components';

const codes = {
  ArrowUp: 1,
  ArrowDown: 2,
  ArrowLeft: 4,
  ArrowRight: 8,
};

const widths = ['100%', '48%', '48%', '100%'];

const actions: Array<keyof typeof codes> = [
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
  'ArrowDown',
];

function getOffset(code: keyof typeof codes) {
  const rotation = getRotation(code) || 0;

  const rads = (rotation / 180) * Math.PI;

  const x = Math.cos(rads);
  const y = Math.sin(rads);

  return {
    x: Math.abs(x) < 0.001 ? 0 : x,
    y: Math.abs(y) < 0.001 ? 0 : y,
  };
}

function getRotation(code: keyof typeof codes) {
  if (code === 'ArrowUp') {
    return -90;
  }
  if (code === 'ArrowRight') {
    return 0;
  }
  if (code === 'ArrowLeft') {
    return 180;
  }
  if (code === 'ArrowDown') {
    return 90;
  }
}

export function CameraControls({
  id,
  send,
  evtSource,
  onClick,
}: {
  id: string | undefined;
  send: ((data: string) => void) | undefined;
  evtSource: EventEmitter | undefined;
  onClick?: () => void;
}) {
  const state = useRef(0);

  const sendState = useCallback(() => {
    if (send) {
      const current = String.fromCharCode(state.current);
      console.log(`>> SENDING`, current);
      send(current);
    }
  }, [send]);

  const enable = useCallback((c: string) => {
    if (!(c in codes)) {
      return;
    }
    const code = codes[c as keyof typeof codes];
    state.current |= code;
  }, []);
  const disable = useCallback((c: string) => {
    if (!(c in codes)) {
      return;
    }
    const code = codes[c as keyof typeof codes];
    state.current &= ~code;
  }, []);

  useEffect(() => {
    // if (send && evtSource) {
    //   evtSource.on("rtcMessage", (d) => {
    //     const data = decoder.decode(d);
    //     switch (data) {
    //       case "camera-activated":
    //         setEnabled(true);
    //         break;
    //     }
    //   });
    // } else {
    //   setEnabled(false);
    // }

    const down = (ev: KeyboardEvent) => enable(ev.code);
    const up = (ev: KeyboardEvent) => disable(ev.code);
    window.addEventListener('keydown', down);
    window.addEventListener('keyup', up);

    const repeat = setInterval(sendState, 30);

    return () => {
      clearInterval(repeat);
      window.removeEventListener('keydown', down);
      window.removeEventListener('keyup', up);
    };
  }, [send, evtSource]);

  return (
    <Container onClick={onClick}>
      {send && id && (
        <div
          style={{
            userSelect: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            position: 'relative',
            left: -32,
            top: -32,
          }}
        >
          {actions.map((d, i) => {
            const { x, y } = getOffset(d);

            return (
              <Btn
                style={{
                  position: 'absolute',
                  left: x * 64,
                  top: y * 64,
                }}
                key={d}
                onMouseDown={(ev) => {
                  ev.preventDefault();
                  enable(d);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseUp={() => disable(d)}
                onTouchStart={() => enable(d)}
                onTouchEnd={() => disable(d)}
                rotation={getRotation(d)}
              ></Btn>
            );
          })}
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.button<{ rotation?: number }>`
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  background-image: url(${chevronRight});
  border: none;
  width: 64px;
  height: 64px;

  transform: rotate(${(p) => p.rotation || 0}deg);
  cursor: pointer;
  opacity: 0.4;
  z-index: 100;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 1;
  }
`;
