import { ReactNode, createRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import './App.css';
import { post, p, useSignaling, Signal } from './media';
import { CameraControls } from './CameraControls';
import { Button } from './common/Button';
import { get } from './media';

const isCustomer = window.electronApi !== undefined;

if (isCustomer) {
  const evs = { ArrowUp: 1, ArrowDown: 2, ArrowLeft: 4, ArrowRight: 8 };
  window.addEventListener('keydown', (ev) => {
    if (ev.code in evs)
      window.electronApi?.ptzEvent(evs[ev.code as keyof typeof evs]);
  });
}

function usePollRoom(id: string) {
  const [room, setRoom] = useState('');
  const roomRef = useRef(room);
  roomRef.current = room;

  useEffect(() => {
    const timer = setInterval(() => {
      get(`/rooms/${id}`)
        .then((res) => {
          setRoom(res ? res.id : '');
        })
        .catch((e) => {});
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return room;
}

function useConnection(id?: string) {
  const [connection, setConnection] = useState<{
    id: string;
    events: EventSource;
  }>();

  useEffect(() => {
    const eventsp = post(
      '/connect',
      id
        ? {
            id,
          }
        : undefined,
    ).then(({ id }: { id: string }) => {
      const events = new EventSource(`/signal/peer/${id}/events`);
      events.addEventListener('message', (e) => {
        const { kind, payload } = JSON.parse(e.data);
        switch (kind) {
          case 'ping': {
            console.log('PING', payload);
            e.stopImmediatePropagation();
            break;
          }
          case 'close': {
            console.log(window.location.reload());
            e.stopImmediatePropagation();
            break;
          }
        }
      });
      setConnection({
        id,
        events,
      });
      return events;
    });
    return () => {
      eventsp.then((e) => e.close());
    };
  }, []);
  return connection;
}

const BaseVideo = styled.video`
  margin: 0;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -2;
  aspect-ratio: 4/3;
`;

const Fallback = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: lightgrey;
  z-index: -3;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const ID = styled.div`
  position: absolute;
  top: 0;
  font-size: 8pt;
  margin: 0px 2px;
  padding: 0px 2px;
  left: -2px;
  height: 1.2em;
  border-radius: 0 0 0.3em 0;
  background: white;
  z-index: 1;
`;

const Video = ({
  id,
  media,
  className,
  select,
  children,
}: {
  id?: string;
  media: MediaStream;
  className?: string;
  select: () => void;
  children?: ReactNode;
}) => {
  const ref = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.srcObject = media;
    }
  }, [media, ref.current]);

  return (
    <div className={className}>
      <div style={{ position: 'relative', width: '100%', maxHeight: '100%' }}>
        <BaseVideo ref={(r) => (ref.current = r)} autoPlay onClick={select} />
        <Fallback
          onClick={() => {
            console.log('fallback clicked');
          }}
        >
          <div>No video</div>
        </Fallback>
        <ID>{id}</ID>

        {children}
      </div>
    </div>
  );
};

const HOST_ID = 'AAAA';
const ROOM_ID = 'ROOM';

function isCreator() {
  return window.location.pathname.slice(0, 4) === '/luo' || isCustomer;
}

function App() {
  const creator = isCreator();

  const connection = useConnection(creator ? HOST_ID : undefined);
  const signal = useSignaling(connection?.id, connection?.events);

  useEffect(() => {
    signal?.evtSource?.on('rtcMessage', ({ payload }) => {
      if (window.electronApi) {
        const state = payload.charCodeAt(0);
        window.electronApi.ptzEvent(state);
      }
    });
  }, [signal?.evtSource]);

  useEffect(() => {
    if (!signal.id) {
      return;
    }

    if (creator) {
      (async () => {
        if (!signal.id) {
          return;
        }

        await post(p(signal.id, '/room'), {
          id: ROOM_ID,
        });
      })();
    } else {
      (async () => {
        await post(p(HOST_ID, `addPeer/${signal.id}`), { roomId: ROOM_ID });
      })();
    }
  }, [signal.id]);

  const [selectedFeedId, setSelectedFeedId] = useState<string>();

  return (
    <Container>
      {signal.id ? (
        <>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Feeds
              selectedId={selectedFeedId}
              signal={signal}
              selectFeed={setSelectedFeedId}
            />
          </div>

          <Connected signal={signal} selectedFeedId={selectedFeedId} />
        </>
      ) : (
        <Connect signal={signal} />
      )}
    </Container>
  );
}

const Connected = ({
  signal,
  selectedFeedId,
}: {
  signal: Signal;
  selectedFeedId: string | undefined;
}) => {
  if (!signal.myVideo || !signal.id) throw new Error('no own media stream');
  return (
    <OwnContainer>
      <MyVideo id={signal.id} media={signal.myVideo} select={() => {}} />
    </OwnContainer>
  );
};

const Feeds = ({
  signal,
  selectFeed,
  selectedId,
}: {
  signal: Signal;
  selectFeed: (id?: string) => void;
  selectedId?: string;
}) => {
  const entries = Object.entries(signal.peers);

  return (
    <FeedContainer columns={Math.min(entries.length, 3)}>
      {entries.map(([id, p]) => {
        const select = () => selectFeed(selectedId === id ? undefined : id);

        return (
          <OtherVideo
            highlighted={selectedId === id}
            key={id}
            id={id}
            media={p.media}
            select={select}
          >
            {selectedId === id && (
              <CameraControls
                id={id}
                evtSource={signal.evtSource}
                send={signal.peers[id].sendData}
                onClick={select}
              />
            )}
          </OtherVideo>
        );
      })}
    </FeedContainer>
  );
};

const FeedContainer = styled.div<{ columns?: number }>`
  width: 100%;

  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(${(p) => p.columns ?? 3}, 1fr);
  justify-items: center;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

const OwnContainer = styled.div`
  display: flex;
  justify-content: ${isCustomer ? 'flex-end' : 'space-between'};
  align-items: flex-end;
  width: 100%;
`;

const Container = styled.div`
  position: absolute;

  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MyVideo = styled(Video)`
  margin: 10px;
  width: 100px;
  max-height: 100px;
  border: 5px solid black;
  border-radius: 10px;
  display: flex;
`;

const OtherVideo = styled(Video)<{ highlighted?: boolean }>`
  border: 5px solid ${(p) => p.theme.colors.border};

  ${(p) =>
    p.highlighted &&
    `
    border-color: ${p.theme.colors.primary};
  `}
  border-radius: 10px;
  margin: 5px;
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;

  min-width: 40%;
  max-width: 100%;
  max-height: 80vh;
  height: auto !important;
`;

const ConnectBtn = styled(Button)`
  width: 250px;
  top: 30vh;
`;

const AddMembersComp = ({ id, room }: { id: string; room: string }) => {
  const [peer, setPeer] = useState<string>('');
  const addMember = async () => {
    await post(p(id, `addPeer/${peer}`), { roomId: room });
    setPeer('');
  };
  return (
    <>
      <input
        type="text"
        placeholder="ID"
        onChange={(ev) => setPeer(ev.currentTarget.value)}
      />
      <Button onClick={addMember}>Add</Button>
    </>
  );
};

const Connect = ({ signal }: { signal: Signal }) => {
  const pathname = window.location.pathname;

  const room = usePollRoom(ROOM_ID);

  if (pathname.toLocaleLowerCase().slice(0, 4) === '/luo') {
    return (
      <ConnectContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <Button disabled={Boolean(room)} onClick={() => signal.connectRtc()}>
            Käynnistä puhelu
          </Button>

          <Button
            onClick={() => {
              post('clean').then(() => window.location.reload());
            }}
          >
            Tyhjennä
          </Button>
        </div>
      </ConnectContainer>
    );
  }

  return (
    <>
      <ConnectBtn disabled={!room} onClick={() => signal.connectRtc()}>
        Soita asiakkaalle
      </ConnectBtn>
    </>
  );
};

const ConnectContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

export default App;
